import { Grid, Typography, Box } from "@mui/material"
import React from "react"
import Layout from "../components/Layout/Layout"
import "../components/Layout/layout.css"
import "./style/traningPage.scss"
import HubspotContactForm from "../components/ContactForm/HubspotContactForm.js"
import { Helmet } from "react-helmet"
const TrainingCassandra = () => {
  return (
    <Layout>
      <Helmet>
        <title>Training - Cassandra Link</title>
        <meta
          name="description"
          content="Training resources for Cassandra on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:title" content="Cassandra Link - Training" />
        <meta
          property="og:description"
          content="Training resources for Cassandra on Cassandra Link - Your Source for Cassandra Resources"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Cassandra Link - Your Source for Cassandra Resources",
            keywords: "Cassandra Link, Training Cassandra, Cassandra",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cassandra.link/training" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cassandra Link - Training" />
        <meta
          name="twitter:description"
          content="Training resources for Cassandra on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta name="twitter:image" content={"../../images/Logo.svg"} />
      </Helmet>
      <Grid container className="contentWrapperTraining">
        <Grid item xs={12} className="headerLearnPage">
          <Typography variant="h3" className="mobText">
            Master your Appache Cassandra knowledge with our Traning
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingY: "50px" }}>
          <Box sx={{ width: { md: "60%" }, mx: "auto" }}>
            <HubspotContactForm />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default TrainingCassandra
