import React, { useEffect, useRef } from "react"
import { Box, Typography } from "@mui/material"
const ContactForm = ({ isInContactPage }) => {
  const formRef = useRef(null)

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "//js.hsforms.net/forms/embed/v2.js"
    script.type = "text/javascript"
    script.async = true

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "4754261",
          formId: "28e34d26-94c1-4759-9d80-2ef615b20e05",
          target: `#${formRef.current.id}`,
        })
      }
    }

    document.body.appendChild(script)

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <Box
        id="formContainer"
        sx={{
          width: { xs: "90%", lg: "100%" },
          mx: { xs: "auto" },
        }}
      >
        <Box
          sx={{
            border: `${isInContactPage ? "2px" : "1px"} solid ${
              isInContactPage ? "#FFA62B" : "#333146"
            }`,
            padding: 5,
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
            minHeight: { lg: "500px" },
            display: "flex",
            justifyContent: "center",
            placeItems: "center",
          }}
          id="formComponent"
          ref={formRef}
        ></Box>
        {!isInContactPage && (
          <Typography
            sx={{
              marginTop: 2,
              fontFamily: "Lato, sans-serif",
              color: "#FFFFFF",
              textAlign: "left",
              width: "100%",
              fontSize: { xs: 13, sm: 16, lg: 18 },
            }}
          >
            *By submitting this form, you agree to the Cassandra Tools’ Terms of
            Service.
          </Typography>
        )}
      </Box>
    </>
  )
}

export default ContactForm
